<template>
  <div class="home">
    <!-- {{ certificate_url() }} -->
    <pdf :src="certificate_url()"></pdf>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import toHex from "to-hex";

export default {
  name: "Certificate",
  props: {
    msg: String,
  },
  components: {
    pdf,
  },
  methods: {
    certificate_id: function () {
      return toHex(this.$route.params.id);
    },
    certificate_url: function () {
      return `certificate/${this.certificate_id()}.pdf`;
    },
  },
};
</script>
